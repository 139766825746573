/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material/styles';

export const categoryHeadingSX: SxProps<Theme> = (theme) => ({
	fontSize: '1.25rem',
	color: theme.palette.text.primary,
});
