/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material/styles';

export const categoryImageSX: SxProps<Theme> = {
	transition: (theme) => theme.transitions.create('filter'),

	'&:hover': {
		filter: 'brightness(1.25)',
	},

	'& img': {
		display: 'block',
	},
};
