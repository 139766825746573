/* eslint-disable complexity */
/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { Img } from '@/components/blocks/MaterialImage';
import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { categoryHeadingSX } from '@/components/content/Category/styles/heading';
import { categoryImageSX } from '@/components/content/Category/styles/image';
import { useCategory } from '@/data/Content/Category';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useUser } from '@/data/User';
import { ContentContext } from '@/data/context/content';
import { ID } from '@/data/types/Basic';
import { CategoryType } from '@/data/types/Category';
import { getContractIdFromContext } from '@/utils/getContractIdFromContext';
import { Grid, Typography } from '@mui/material';
import { FC, MouseEvent, useCallback, useContext, useEffect } from 'react';

export const Category: FC<{
	id: ID;
	variant?: string;
	clickAction?: (event?: MouseEvent) => void;
}> = ({ id, variant, clickAction }) => {
	const router = useNextRouter();
	const { rawData, category, loading } = useCategory(id);
	const { user } = useUser();
	const contract = getContractIdFromContext(user?.context);
	const { onNotify } = useContext(ContentContext) as {
		onNotify: (id: ID, contract: string, category?: CategoryType) => void;
	};
	const onClick = useCallback(
		(event: MouseEvent) => {
			if (clickAction) {
				clickAction(event);
			}
			router.push(category?.seo.href as string);
		},
		[category?.seo.href, clickAction, router]
	);

	useEffect(() => {
		if (onNotify && rawData) {
			onNotify(id, contract, category);
		}
	}, [loading, rawData, category]); // eslint-disable-line react-hooks/exhaustive-deps

	return loading ? (
		<ProgressIndicator />
	) : category ? (
		// TODO Hoist href to top level category object, presentation shouldn't have to handle this structure.
		<Grid item xs={6} sm={3} md={2.4} lg={2} key={category.uniqueID}>
			<Linkable
				href={category.seo.href || ''}
				data-testid={category.seo.href || ''}
				id={category.seo.href || ''}
				sx={categoryImageSX}
			>
				<Img
					width="100%"
					alt={category.shortDescription}
					src={category.fullImage || category.thumbnail || ''}
				/>
				<Typography sx={categoryHeadingSX}>{category.name}</Typography>
			</Linkable>
		</Grid>
	) : null;
};
