import { ID } from '@/data/types/Basic';
import { Cache } from '@/data/types/Cache';
import { GetServerSidePropsContext } from 'next';

import {
	DATA_KEY,
	cacheCategories,
	fetcher,
	getCategory,
	getCategoryExtended,
	getCategoryFetchPayload,
	parseChildCategoryId,
} from '@/core/data/Content/_Category';

// export const getCategory = async (cache: Cache, id: ID, context: GetServerSidePropsContext) => {
// 	const value = await getCategoryExtended(cache, { id }, context);
// 	return value?.at(0)?.name;
// };

export const getCategoryTitle = async (
	cache: Cache,
	id: ID,
	context: GetServerSidePropsContext
) => {
	const value = await getCategoryExtended(cache, { id }, context);
	return value?.at(0)?.name;
};

export const getCategoryShortDesc = async (
	cache: Cache,
	id: ID,
	context: GetServerSidePropsContext
) => {
	const value = await getCategoryExtended(cache, { id }, context);
	return value?.at(0)?.shortDescription;
};

// the core file has been overriden, so here we just need to get the value

export const getCategoryLongDesc = async (
	cache: Cache,
	id: ID,
	context: GetServerSidePropsContext
) => {
	const value = await getCategoryExtended(cache, { id }, context);
	return value?.at(0)?.longDescription;
};

// this function can be used if the core file is not changed
//
// export const getCategoryLongDesc = async (cache: Cache, id: ID, context: GetServerSidePropsContext) => {
// 	const value = await getCategoryExtended(cache, { id }, context);
// 	const category = value?.at(0) as unknown as any;
// 	const longDescription = category?.longDescription;
// 	return longDescription;
// };

export const getCategoryImage = async (
	cache: Cache,
	id: ID,
	context: GetServerSidePropsContext
) => {
	const value = await getCategoryExtended(cache, { id }, context);
	return value?.at(0)?.fullImage;
};

export {
	DATA_KEY,
	cacheCategories,
	fetcher,
	getCategory,
	getCategoryExtended,
	getCategoryFetchPayload,
	parseChildCategoryId,
};
